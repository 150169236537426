import React from 'react'
import '../assets/ComponentDesign/LoadingStyle.css';
const LoadingStyle = () => {
  return (
    <>
    <div class="loader">
  <span>Loading...</span>
</div>
</>
  )
}

export default LoadingStyle
